import { Router } from '@angular/router';
import { CoffeeService } from './../../services/coffee/coffee.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Coffee } from 'src/app/models/coffee.model';

@Component({
  selector: 'app-listar-cafes-page',
  templateUrl: './listar-cafes-page.component.html',
  styleUrls: ['./listar-cafes-page.component.css']
})
export class ListarCafesPageComponent implements OnInit {
  coffees$: Observable<Coffee[]>;

  displayedColumns: string[] = ['name', 'id', 'loja', 'editar', 'excluir'];

  constructor(
    private coffeeService: CoffeeService,
    private router: Router
  ) {
    this.coffees$ = this.coffeeService.coffees$;
  }

  ngOnInit(): void {
  }

  editCoffee(coffee: Coffee) {
    this.router.navigate(['/cadastrar-cafe'], {
      queryParams: {
        id: coffee.id
      }
    });
  }

  createCoffee() {
    this.router.navigateByUrl('cadastrar-cafe');
  }

  deleteCoffee(coffee: Coffee) {

  }

}
