import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { NgForm } from '@angular/forms';
import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
    user$: Observable<User>;

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.user$ = this.authService.user$;
    }

    async onSubmit(form: NgForm) {

        const values = form.value;

        await this.authService.login(values.email, values.pass);

        this.user$.subscribe(
            user => {
                if (user) {
                    this.router.navigateByUrl('');
                }
            }
        );

    }

}
