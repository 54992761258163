<div style="margin-top: 25px;" class="endereco-form">

    
    <form ngForm fxLayout="column" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onSubmit(f)">
        <h1 style="margin: 30px auto; display: block; font-size: 25px;">Endereço de entrega</h1>
        <mat-form-field>
            <input type="text" matInput ngModel name="nome" placeholder="Nome da Empresa ou Pessoa Físca" required >
        </mat-form-field>
        <mat-form-field>
            <input type="email" matInput ngModel name="email" placeholder="Email" required>
        </mat-form-field>
        <mat-form-field>
            <input type="number" matInput ngModel name="cep" placeholder="CEP" required>
        </mat-form-field>
        <mat-form-field>
            <input type="text" matInput ngModel name="logradouro" placeholder="Logradouro" required>
        </mat-form-field>
        <mat-form-field>
            <input type="text" matInput ngModel name="numero" placeholder="Número" required>
        </mat-form-field>
        <mat-form-field>
            <input type="text" matInput ngModel name="complemento" placeholder="Complemento" required>
        </mat-form-field>
        <mat-form-field>
            <input type="text" matInput ngModel name="bairro" placeholder="Bairro" required>
        </mat-form-field>
        <mat-form-field>
            <input type="text" matInput ngModel name="cidade" placeholder="Cidade" required >
        </mat-form-field>
        
        <button type="submit" mat-raised-button>Avançar</button>
    </form>
</div>