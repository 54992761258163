import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Loja } from 'src/app/models/loja.model';

@Injectable({
  providedIn: 'root'
})
export class LojaService {
  lojas$: Observable<Loja[]>;
  private lojasCollection: AngularFirestoreCollection<Loja>;

  constructor(
    private firestore: AngularFirestore
  ) {

    this.lojasCollection = firestore.collection<Loja>('lojas');

    this.lojas$ = this.lojasCollection.valueChanges({ idField: 'id' });

  }

  createLoja(loja: Loja) {
    if (!loja.id) {
      loja.id = this.firestore.createId();
    }

    return this.lojasCollection.doc(loja.id).set(loja);
  }

  updateLoja(loja: Loja) {
    return this.lojasCollection.doc(loja.id).update(loja);
  }

  deleteLoja(loja: Loja) {
    return this.lojasCollection.doc(loja.id).delete();
  }
}
