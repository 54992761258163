<form ngForm fxLayout="column" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onSubmit(f)">
    <h1 style="margin: 30px auto; display: block; font-size: 25px;">
        {{option.name === undefined ? 'Cadastro de Opção' : option.name === '' ? 'Cadastro de Opção' : option.name}}
    </h1>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="option.name" name="name" placeholder="Nome da Opção" required>
    </mat-form-field>

    <mat-form-field>
        <input type="number" matInput ngModel [(ngModel)]="option.pesoGramas" name="peso" placeholder="Peso em Gramas"
            required>
    </mat-form-field>

    <mat-form-field>

        <mat-label>Moagem</mat-label>
        <mat-select ngModel name="moagem" [(ngModel)]="option.moagem" required>

            <mat-option *ngFor="let moagem of moagens" [value]="moagem.value">{{ moagem.viewValue }}</mat-option>

        </mat-select>

    </mat-form-field>

    <mat-form-field>
        <input type="number" matInput ngModel [(ngModel)]="option.price" name="price" placeholder="Preço sem Promoção"
            required>
    </mat-form-field>

    <mat-form-field>

        <mat-label>Está em promoção?</mat-label>
        <mat-select ngModel name="isOnSale" [(ngModel)]="option.isOnSale" required>

            <mat-option *ngFor="let op of sale" [value]="op.value">{{ op.viewValue }}</mat-option>

        </mat-select>

    </mat-form-field>

    <mat-form-field>
        <input type="number" matInput ngModel [(ngModel)]="option.salePrice" name="preco"
            placeholder="Preço Promocional">
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancelar</button>
        <button mat-button type="submit">{{ buttonText }}</button>
    </div>
</form>