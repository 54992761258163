import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'src/app/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    user$: Observable<User>;
    hasPermission$: Observable<boolean>;
    hasAdminPermission: (data: any) => Observable<boolean>;

    constructor(
        private auth: AngularFireAuth,
        private firestore: AngularFirestore,
        private functions: AngularFireFunctions
    ) {

        this.hasAdminPermission = this.functions.httpsCallable('hasAdminPermission');

        this.auth.authState.pipe(
            switchMap(user => {
                if (user) {

                    return this.hasAdminPermission({ email: user.email });

                }
                return of(false);
            })
        ).subscribe(
            hasPermission => {
                if (!hasPermission) {
                    this.auth.signOut();
                }
            }
        );

        const userObs = this.auth.authState;

        this.user$ = userObs.pipe(
            switchMap((user) => {

                if (user) {
                    return this.firestore.doc<User>(`users/${user.uid}`).valueChanges();
                }

                auth.signOut();

                return of(null);
            })
        );

        // this.user$.subscribe(console.log);

    }

    async login(email: string, password: string) {

        const hasPermission = await this.hasAdminPermission({ email }).toPromise();

        // console.log(email);
        // console.log(password);
        // console.log(hasPermission);

        if (hasPermission) {
            await this.auth.signInWithEmailAndPassword(email, password);
        }

    }

    async logout() {
        this.auth.signOut();
    }




}
