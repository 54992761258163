<form ngForm fxLayout="column" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onSubmit(f)">
    <h1 style="margin: 30px auto; display: block; font-size: 25px;">
        {{coffee.name === undefined ? 'Cadastro do Café' : coffee.name === '' ? 'Cadastro do Café' : coffee.name}}</h1>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.name" name="name" placeholder="Nome do Café" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.image" name="image" placeholder="URL da Foto do Café"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="number" matInput ngModel [(ngModel)]="coffee.pontuacao" name="pontuacao"
            placeholder="Pontuação SCA" required>
    </mat-form-field>

    <mat-form-field>

        <mat-label>Torra</mat-label>
        <mat-select ngModel name="torra" [(ngModel)]="coffee.torra">
            <mat-option *ngFor="let torra of torras" [value]="torra.value">
                {{torra.viewValue}}
            </mat-option>
        </mat-select>

    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.tastingNotes[0]" name="tastingNote0"
            placeholder="Nota Aromática 1" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.tastingNotes[1]" name="tastingNote1"
            placeholder="Nota Aromática 2" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.tastingNotes[2]" name="tastingNote2"
            placeholder="Nota Aromática 3" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.description" name="description" placeholder="Descrição"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.secagem" name="secagem"
            placeholder="Processo de Secagem" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.variety" name="variedade"
            placeholder="Variedade do Café" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.macroCaracteristica" name="macroCaracteristica"
            placeholder="Macro Característica do Café" required>
    </mat-form-field>

    <mat-form-field>
        <input type="number" matInput ngModel [(ngModel)]="coffee.altitude" name="altitude"
            placeholder="Altitude em Metros" required>
    </mat-form-field>

    <mat-form-field>
        <input type="number" matInput ngModel [(ngModel)]="coffee.acidez" name="acidez" placeholder="Acidez de 0 a 100"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="number" matInput ngModel [(ngModel)]="coffee.docura" name="docura" placeholder="Doçura de 0 a 100"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.producer" name="producer" placeholder="Nome do Produtor"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.story" name="story" placeholder="História do Café"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.coffeeFarm.cityName" name="cityName"
            placeholder="Cidade - Estado" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.coffeeFarm.name" name="farmName"
            placeholder="Nome da Fazenda" required>
    </mat-form-field>

    <mat-form-field>
        <input type="url" matInput ngModel [(ngModel)]="coffee.coffeeFarm.picture" name="farmPicture"
            placeholder="URL da Foto da Fazenda" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="coffee.coffeeFarm.region" name="producer"
            placeholder="Nome da Região Produtora" required>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="options-title">
        <h3 fxFlex>{{ coffee.store.name ? coffee.store.name : 'Nenhuma Loja Selecionada' }}</h3>

        <button fxFlex mat-button (click)="assignLoja()">Selecionar Loja</button>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="options-title">
        <h3 fxFlex>Opções disponíveis</h3>

        <button fxFlex mat-button (click)="createOption()">Cadastrar Opção</button>
    </div>


    <table mat-table [dataSource]="coffee.options" class="mat-elevation-z8">

        <!-- Position Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="peso">
            <th mat-header-cell *matHeaderCellDef> Peso </th>
            <td mat-cell *matCellDef="let element"> {{element.pesoGramas}}g </td>
        </ng-container>

        <ng-container matColumnDef="moagem">
            <th mat-header-cell *matHeaderCellDef> Moagem </th>
            <td mat-cell *matCellDef="let element"> {{element.moagem === 0 ? 'Moído' : 'Em Grãos' }} </td>
        </ng-container>

        <ng-container matColumnDef="preco">
            <th mat-header-cell *matHeaderCellDef> Preço </th>
            <td mat-cell *matCellDef="let element">
                {{ element.price ? element.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="sale">
            <th mat-header-cell *matHeaderCellDef> Em promoção </th>
            <td mat-cell *matCellDef="let element">
                {{element.isOnSale === true ? 'Sim' : 'Não' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="saleprice">
            <th mat-header-cell *matHeaderCellDef> Preço promocional </th>
            <td mat-cell *matCellDef="let element">
                {{ element.salePrice ? element.salePrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="editar">
            <th mat-header-cell *matHeaderCellDef> Editar </th>
            <td mat-cell *matCellDef="let element">
                <a mat-icon-button (click)="editOption(element)">
                    <mat-icon class="mat-18">edit</mat-icon>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="excluir">
            <th mat-header-cell *matHeaderCellDef> Excluir </th>
            <td mat-cell *matCellDef="let element">
                <a mat-icon-button (click)="deleteOption(element)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!-- </div> -->





    <button type="submit" mat-raised-button>Salvar</button>
</form>