import { NgForm } from '@angular/forms';
import { Option } from './../../models/option.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-create-option-dialog',
    templateUrl: './create-option-dialog.component.html',
    styleUrls: ['./create-option-dialog.component.css']
})
export class CreateOptionDialogComponent implements OnInit {
    option = {} as Option;
    buttonText = 'Criar Opção';

    moagens = [
        {
            viewValue: 'Moído',
            value: 0
        },
        {
            viewValue: 'Em Grãos',
            value: 1
        },
    ];

    sale = [
        {
            viewValue: 'Sim',
            value: true
        },
        {
            viewValue: 'Nao',
            value: false
        },
    ];

    constructor(
        public dialogRef: MatDialogRef<CreateOptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.option = data;
            this.buttonText = 'Editar Opção';
        }
    }

    ngOnInit(): void {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(form: NgForm) {
        if (form.valid) {
            this.dialogRef.close(this.option);
        }
    }

}
