import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Loja } from 'src/app/models/loja.model';
import { LojaService } from 'src/app/services/loja/loja.service';

@Component({
  selector: 'app-delete-loja-dialog',
  templateUrl: './delete-loja-dialog.component.html',
  styleUrls: ['./delete-loja-dialog.component.css']
})
export class DeleteLojaDialogComponent implements OnInit {
  loja: Loja;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteLojaDialogComponent>,
    private lojaService: LojaService
  ) {
    this.loja = this.data;
  }

  deleteLoja() {
    this.lojaService.deleteLoja(this.loja);
    this.dialogRef.close();
  }

  ngOnInit() {

  }

}
