import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-payment-finished-page',
    templateUrl: './payment-finished-page.component.html',
    styleUrls: ['./payment-finished-page.component.css']
})
export class PaymentFinishedPageComponent implements OnInit {
    queryParams$: Observable<Params>;
    text;
    paymentId;

    constructor(
        private route: ActivatedRoute,
    ) {

    }

    ngOnInit(): void {
        this.queryParams$ = this.route.queryParams;

        this.queryParams$.subscribe(
            params => {
                const collectionId = params.collection_id;
                const collectionStatus = params.collection_status;

                this.paymentId = collectionId;

                if (collectionStatus === 'approved') {
                    this.text = 'O seu pagamento foi aprovado com sucesso. Obrigado! Um e-mail do Mercado Pago será enviado.';
                } else if (collectionStatus === 'pending') {
                    this.text = 'O seu pagamento está pendente. Aguarde email do Mercado Pago para confirmação.';
                } else {
                    this.text = 'O pagamento foi recusado. Tente novamente mais tarde ou contate o seu banco administrador';
                }

            }
        );
    }

}
