import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { NgxChartsModule } from '@swimlane/ngx-charts';


import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

import { HomePageComponent } from './pages/home-page/home-page.component';
import { UserDataPageComponent } from './pages/user-data-page/user-data-page.component';
import { PaymentFinishedPageComponent } from './pages/payment-finished-page/payment-finished-page.component';
import { FormsModule } from '@angular/forms';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { PedidosPageComponent } from './pages/pedidos-page/pedidos-page.component';
import { CadastrarCafePageComponent } from './pages/cadastrar-cafe-page/cadastrar-cafe-page.component';
import { CreateOptionDialogComponent } from './dialogs/create-option-dialog/create-option-dialog.component';
import { CoffeesPageComponent } from './pages/coffees-page/coffees-page.component';
import { ListarLojasDialogComponent } from './dialogs/listar-lojas-dialog/listar-lojas-dialog.component';
import { CreateLojaDialogComponent } from './dialogs/create-loja-dialog/create-loja-dialog.component';
import { DeleteLojaDialogComponent } from './dialogs/delete-loja-dialog/delete-loja-dialog.component';
import { ListarCafesPageComponent } from './pages/listar-cafes-page/listar-cafes-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    UserDataPageComponent,
    PaymentFinishedPageComponent,
    LoginPageComponent,
    DashboardPageComponent,
    PedidosPageComponent,
    CadastrarCafePageComponent,
    CreateOptionDialogComponent,
    CoffeesPageComponent,
    ListarLojasDialogComponent,
    CreateLojaDialogComponent,
    DeleteLojaDialogComponent,
    ListarCafesPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    FormsModule,
    NgxChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
