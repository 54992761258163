<form ngForm fxLayout="column" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onSubmit(f)">
    <h1 style="margin: 30px auto; display: block; font-size: 25px;">
        {{loja.name === undefined ? title : loja.name === '' ? title : loja.name}}</h1>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="loja.id" name="id" placeholder="ID Único da Loja" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="loja.name" name="name" placeholder="Nome da Loja" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="loja.cidade" name="cidade" placeholder="Cidade e Estado"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="url" matInput ngModel [(ngModel)]="loja.picture" name="picture"
            placeholder="URL da Foto do Menu Produtores" required>
    </mat-form-field>

    <mat-form-field>
        <input type="url" matInput ngModel [(ngModel)]="loja.capa" name="capa" placeholder="URL da Foto de Capa"
            required>
    </mat-form-field>

    <mat-form-field>
        <input type="url" matInput ngModel [(ngModel)]="loja.profile" name="profile"
            placeholder="URL da Foto do Perfil (Logomarca)" required>
    </mat-form-field>

    <mat-form-field>
        <input type="text" matInput ngModel [(ngModel)]="loja.story" name="story" placeholder="História da Loja"
            required>
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancelar</button>
        <button mat-button type="submit">Salvar</button>
    </div>
</form>