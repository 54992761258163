import { LojaService } from './../../services/loja/loja.service';
import { NgForm } from '@angular/forms';
import { Loja } from './../../models/loja.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-loja-dialog',
  templateUrl: './create-loja-dialog.component.html',
  styleUrls: ['./create-loja-dialog.component.css']
})
export class CreateLojaDialogComponent implements OnInit {
  loja = {} as Loja;

  title: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateLojaDialogComponent>,
    private lojaService: LojaService
  ) {
    if (data) {
      this.loja = data;
      this.title = 'Editar Loja';
    } else {
      this.title = 'Criar Loja';
    }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form: NgForm) {
    if (form.valid) {

      if (this.data) {
        this.lojaService.updateLoja(this.loja);
      } else {
        this.lojaService.createLoja(this.loja);
      }

      this.dialogRef.close();
    }
  }

}
