<div class="page-wrapper">
    
    <div class="logo">
        <img src="assets/logos/logo_cor@4x.svg">
    </div>

    <div class="text">
        <h3>Insira o código:</h3>
    </div>

    <div class="text-box">
        <form (ngSubmit)="onSubmit(form)" #form="ngForm">
            <mat-form-field>
                <input matInput ngModel name="codigo" placeholder="Código" required>
            </mat-form-field>
        </form>
    </div>

</div>