import { DbService } from './../../services/db.service';
import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { get } from 'scriptjs';

@Component({
    selector: 'app-user-data-page',
    templateUrl: './user-data-page.component.html',
    styleUrls: ['./user-data-page.component.css']
})
export class UserDataPageComponent implements OnInit {
    initPoint: any;
    constructor(
        private dbService: DbService
    ) { }

    ngOnInit(): void {
        get('https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js', () => {
            // library has been loaded...
        });
    }

    onSubmit(form: NgForm) {
        const values = form.value;

        const observable = this.dbService.createCheckout(values);

        observable.subscribe(
            res => {
                // Read result of the Cloud Function.
                this.initPoint = res.result;
                window.location.href = this.initPoint;
            }
        );
    }

}
