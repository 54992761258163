<table mat-table [dataSource]="lojas$" class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef> Selecionar </th>
        <td mat-cell *matCellDef="let element">
            <a mat-icon-button (click)="selectLoja(element)">
                <mat-icon class="mat-18">check_circle</mat-icon>
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="editar">
        <th mat-header-cell *matHeaderCellDef> Editar </th>
        <td mat-cell *matCellDef="let element">
            <a mat-icon-button (click)="editLoja(element)">
                <mat-icon class="mat-18">edit</mat-icon>
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="excluir">
        <th mat-header-cell *matHeaderCellDef> Excluir </th>
        <td mat-cell *matCellDef="let element">
            <a mat-icon-button (click)="deleteLoja(element)">
                <mat-icon class="mat-18">delete</mat-icon>
            </a>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<button mat-button (click)="createLoja()">Criar Nova Loja</button>