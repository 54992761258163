import { ActivatedRoute, Router } from '@angular/router';
import { Loja } from './../../models/loja.model';
import { ListarLojasDialogComponent } from './../../dialogs/listar-lojas-dialog/listar-lojas-dialog.component';
import { CreateOptionDialogComponent } from './../../dialogs/create-option-dialog/create-option-dialog.component';
import { CoffeeFarm } from './../../models/coffee-farm.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Coffee } from 'src/app/models/coffee.model';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { CoffeeService } from 'src/app/services/coffee/coffee.service';

@Component({
    selector: 'app-cadastrar-cafe-page',
    templateUrl: './cadastrar-cafe-page.component.html',
    styleUrls: ['./cadastrar-cafe-page.component.css']
})
export class CadastrarCafePageComponent implements OnInit {
    displayedColumns: string[] = ['name', 'id', 'peso', 'moagem', 'preco', 'sale', 'saleprice', 'editar', 'excluir'];
    update: boolean;

    coffee = {
        tastingNotes: [],
        coffeeFarm: {} as CoffeeFarm,
        options: [],
        store: {} as Loja
    } as Coffee;

    @ViewChild(MatTable) table: MatTable<any>;

    torras = [
        {
            viewValue: 'Clara',
            value: 0
        },
        {
            viewValue: 'Média Clara',
            value: 1
        },
        {
            viewValue: 'Média',
            value: 2
        },
        {
            viewValue: 'Média Escura',
            value: 3
        },
        {
            viewValue: 'Escura',
            value: 4
        },
    ];

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private coffeeService: CoffeeService,
        private router: Router
    ) {
        this.route.queryParams.subscribe(
            params => {
                if (params.id) {
                    this.coffeeService.getCoffee(params.id).subscribe(
                        coffee => this.coffee = coffee
                    );
                    this.update = true;
                } else {
                    this.update = false;

                }
            }
        );
    }

    ngOnInit(): void {
    }

    async onSubmit(form: NgForm) {
        if (form.valid) {
            if (this.update) {
                await this.coffeeService.updateCoffee(this.coffee);
            } else {
                await this.coffeeService.createCoffee(this.coffee);
            }
        }
    }

    createOption() {
        const dialogRef = this.dialog.open(CreateOptionDialogComponent, {
            width: '500px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.coffee.options.push(result);
                this.table.renderRows();
            }
        });
    }

    editOption(option) {
        const dialogRef = this.dialog.open(CreateOptionDialogComponent, {
            width: '500px',
            data: option
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const index = this.coffee.options.findIndex(op => option === op);
                this.coffee.options.splice(index, 1);
                this.coffee.options.push(result);
                this.table.renderRows();
            }
        });
    }

    deleteOption(option) {
        const index = this.coffee.options.findIndex(op => option === op);
        this.coffee.options.splice(index, 1);
        this.table.renderRows();
    }

    assignLoja() {
        const dialogRef = this.dialog.open(ListarLojasDialogComponent, {
            width: '700px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.coffee.store.id = result.id;
                this.coffee.store.name = result.name;
            }
        });
    }

}
