<ng-container *ngIf="(user$ | async) as user">


    <div class="user-greeting-text">
        <div class="hello">Olá,</div>
        <div class="user-name">{{user.displayName}}!</div>
    </div>

    <div fxLayout="column" class="ticket-medio-card card">

        <div class="number-cards">

            <ngx-charts-number-card [scheme]="colorScheme" [results]="dailyNumberCards" [cardColor]="cardColor"
                [labelFormatting]="dayLabelFormat">
            </ngx-charts-number-card>

        </div>

        <div class="product-chart">
            <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="dailyProductsBarChart" [xAxis]="true"
                [yAxis]="true" [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                [xAxisLabel]="'xAxisLabel'" [yAxisLabel]="'yAxisLabel'" [animations]="true">
            </ngx-charts-bar-vertical-stacked>
        </div>



        <div class="evolucao-chart">
            <ngx-charts-line-chart [scheme]="colorScheme" [legend]="true" [showXAxisLabel]="true"
                [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true" [xAxisLabel]="'Semanas'"
                [yAxisLabel]="'Ticket Médio'" [timeline]="true" [results]="[evolucao7semanas[0]]">
            </ngx-charts-line-chart>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>

            <div class="evolucao-chart flex">
                <ngx-charts-line-chart [scheme]="colorScheme" [showXAxisLabel]="true" [showYAxisLabel]="true"
                    [xAxis]="true" [yAxis]="true" [xAxisLabel]="'Semanas'" [yAxisLabel]="'Unidades Vendidas'"
                    [timeline]="true" [results]="[evolucao7semanas[1]]">
                </ngx-charts-line-chart>
            </div>

            <div class="evolucao-chart flex">
                <ngx-charts-line-chart [scheme]="colorScheme" [showXAxisLabel]="true" [showYAxisLabel]="true"
                    [xAxis]="true" [yAxis]="true" [xAxisLabel]="'Semanas'" [yAxisLabel]="'Número de Compras'"
                    [timeline]="true" [results]="[evolucao7semanas[2]]">
                </ngx-charts-line-chart>
            </div>
        </div>

    </div>

    <div fxLayout="column" class="produtos-vendidos-card card">

        <div class="number-cards">

            <ngx-charts-number-card [scheme]="colorScheme" [results]="weeklyNumberCards" [cardColor]="cardColor"
                [labelFormatting]="weekLabelFormat">
            </ngx-charts-number-card>

        </div>

        <div class="product-chart">

            <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="weeklyProductsBarChart" [xAxis]="true"
                [yAxis]="true" [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                [xAxisLabel]="'xAxisLabel'" [yAxisLabel]="'yAxisLabel'" [animations]="true">
            </ngx-charts-bar-vertical-stacked>

        </div>

    </div>

    <div fxLayout="column" class="numero-compras-card card">

        <div class="number-cards">
            <ngx-charts-number-card [scheme]="colorScheme" [results]="monthlyNumberCards" [cardColor]="cardColor"
                [labelFormatting]="monthLabelFormat">
            </ngx-charts-number-card>
        </div>

        <div class="product-chart">
            <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="monthlyProductsBarChart" [xAxis]="true"
                [yAxis]="true" [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                [xAxisLabel]="'xAxisLabel'" [yAxisLabel]="'yAxisLabel'" [animations]="true">
            </ngx-charts-bar-vertical-stacked>
        </div>

    </div>

    <div fxLayout="column" class="numero-compras-card card">

        <div class="number-cards">

            <ngx-charts-number-card [scheme]="colorScheme" [results]="yearlyNumberCards" [cardColor]="cardColor"
                [labelFormatting]="yearLabelFormat">
            </ngx-charts-number-card>

        </div>

        <div class="product-chart">
            <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="yearlyProductsBarChart" [xAxis]="true"
                [yAxis]="true" [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                [xAxisLabel]="'xAxisLabel'" [yAxisLabel]="'yAxisLabel'" [animations]="true">
            </ngx-charts-bar-vertical-stacked>
        </div>

    </div>


</ng-container>