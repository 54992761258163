import { ChartsService } from './../../services/charts/charts.service';
import { OrdersService } from './../../services/orders/orders.service';
import { MetricsService } from './../../services/metrics/metrics.service';
import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SalesMetric } from 'src/app/models/sales-metric.model';
import { User } from 'src/app/models/user.model';

@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {
    user$: Observable<User>;

    dailySales$: Observable<SalesMetric>;
    weeklySales$: Observable<SalesMetric>;
    monthlySales$: Observable<SalesMetric>;
    yearlySales$: Observable<SalesMetric>;

    last7weeksSales$: Observable<SalesMetric[]>;

    dailyData = [];
    weeklyData = [];
    monthlyData = [];
    yearlyData = [];

    dailyTotals = [];
    weeklyTotals = [];
    monthlyTotals = [];
    yearlyTotals = [];

    evolucao7semanas = [];

    dailyNumberCards = [];
    weeklyNumberCards = [];
    monthlyNumberCards = [];
    yearlyNumberCards = [];


    dailyProductsBarChart = [];
    weeklyProductsBarChart = [];
    monthlyProductsBarChart = [];
    yearlyProductsBarChart = [];

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    };
    cardColor = '#232837';

    constructor(
        private authService: AuthService,
        private chartsService: ChartsService
    ) { }

    ngOnInit(): void {

        this.chartsService.evolutionChart$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.evolucao7semanas = chart;
                }
            }
        );

        this.chartsService.dailyNumberCards$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.dailyNumberCards = chart;
                }
            }
        );

        this.chartsService.weeklyNumberCards$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.weeklyNumberCards = chart;
                }
            }
        );

        this.chartsService.monthlyNumberCards$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.monthlyNumberCards = chart;
                }
            }
        );

        this.chartsService.yearlyNumberCards$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.yearlyNumberCards = chart;
                }
            }
        );

        this.chartsService.dailyProductsSales$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.dailyProductsBarChart = chart;
                }
            }
        );

        this.chartsService.weeklyProductsSales$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.weeklyProductsBarChart = chart;
                }
            }
        );

        this.chartsService.monthlyProductsSales$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.monthlyProductsBarChart = chart;
                }
            }
        );

        this.chartsService.yearlyProductsSales$.subscribe(
            chart => {
                if (chart.length > 0) {
                    this.yearlyProductsBarChart = chart;
                }
            }
        );


        this.user$ = this.authService.user$;
    }

    dayLabelFormat(c): string {
        return `${c.label}<br/><small class="number-card-label">Hoje</small>`;
    }

    weekLabelFormat(c): string {
        return `${c.label}<br/><small class="number-card-label">Esta semana</small>`;
    }

    monthLabelFormat(c): string {
        return `${c.label}<br/><small class="number-card-label">Este mês</small>`;
    }

    yearLabelFormat(c): string {
        return `${c.label}<br/><small class="number-card-label">Este ano</small>`;
    }


}
