import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
    providedIn: 'root'
})
export class DbService {

    producerName: string;
    function;

    constructor(
        private firestore: AngularFirestore,
        private functions: AngularFireFunctions
    ) {
        this.function = this.functions.httpsCallable('checkoutMP');
    }

    async checkCode(code: string) {
        return this.firestore.collection('producersCode').doc(code).get().toPromise()
            .then(
                doc => {
                    if (doc.exists) {
                        const data = doc.data;
                        const id = doc.id;

                        this.producerName = data.name;

                        return {
                            found: true,
                            name: this.producerName
                        };
                    }

                    return {
                        found: false
                    }
                }
            )
            .catch(
                error => {
                    return error;
                }
            );
    }

    createCheckout(values) {
        const payer = {
            name: values.name,
            email: values.email,
            address: {
                street_name: values.logradouro,
                street_number: values.numero,
                zip_code: values.cep
            }
        };

        const item = {
            title: 'Festival do Café Digital - 3 meses',
            quantity: 1,
            currency_id: 'BRL',
            unit_price: 526.99,
            description: '3 meses de acesso à plataforma de vendas do Festival do Café.',
        };

        const preference = {
            payer,
            items: [item],
            back_urls: {
                success: environment.url,
                failure: environment.url,
                pending: environment.url
            },
            auto_return: 'all',
        };

        return this.function(preference);


    }
}
