import { Observable } from 'rxjs';
import { MetricsService } from './../metrics/metrics.service';
import { Injectable } from '@angular/core';
import { SalesMetric } from 'src/app/models/sales-metric.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChartsService {
    private last7weeksSales$: Observable<SalesMetric[]>;

    evolutionChart$: Observable<any>;
    dailyNumberCards$: Observable<any>;
    weeklyNumberCards$: Observable<any>;
    monthlyNumberCards$: Observable<any>;
    yearlyNumberCards$: Observable<any>;

    dailyProductsSales$: Observable<any>;
    weeklyProductsSales$: Observable<any>;
    monthlyProductsSales$: Observable<any>;
    yearlyProductsSales$: Observable<any>;

    constructor(
        private metricsService: MetricsService
    ) {
        this.last7weeksSales$ = this.metricsService.last7weeks$;

        this.evolutionChart$ = this.last7weeksSales$.pipe(
            map(weeksSales => {
                const ticketMedioSeries = [];
                const unidadesVendidasSeries = [];
                const numeroDeComprasSeries = [];

                let i = 1;

                weeksSales.forEach(
                    week => {
                        ticketMedioSeries.push(
                            {
                                value: week?.ticketMedio ?? 0.0,
                                name: `Semana ${i}`
                            }
                        );
                        unidadesVendidasSeries.push(
                            {
                                value: week?.unidadesVendidas ?? 0,
                                name: `Semana ${i}`
                            }
                        );
                        numeroDeComprasSeries.push(
                            {
                                value: week?.numeroDeCompras ?? 0,
                                name: `Semana ${i}`
                            }
                        );

                        i += 1;
                    }
                );

                const ticketMedio = {
                    name: 'Ticket Médio',
                    series: ticketMedioSeries
                };
                const unidadesVendidas = {
                    name: 'Unidades Vendidas',
                    series: unidadesVendidasSeries
                };
                const numeroDeCompras = {
                    name: 'Número de Compras',
                    series: numeroDeComprasSeries
                };

                return [
                    ticketMedio,
                    unidadesVendidas,
                    numeroDeCompras
                ];
            })
        );


        this.dailyNumberCards$ = this.metricsService.dailySales$.pipe(
            map(
                dailySales => {
                    const data = [];
                    data.push({
                        name: 'Ticket médio',
                        value: dailySales?.ticketMedio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 'R$ 0,00'
                    });
                    data.push({
                        name: 'Unidades vendidas',
                        value: dailySales?.unidadesVendidas ?? 0
                    });
                    data.push({
                        name: 'Número de compras',
                        value: dailySales?.numeroDeCompras ?? 0
                    });

                    return data;
                }
            )
        );

        this.weeklyNumberCards$ = this.metricsService.weeklySales$.pipe(
            map(
                dailySales => {
                    const data = [];
                    data.push({
                        name: 'Ticket médio',
                        value: dailySales?.ticketMedio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 'R$ 0,00'
                    });
                    data.push({
                        name: 'Unidades vendidas',
                        value: dailySales?.unidadesVendidas ?? 0
                    });
                    data.push({
                        name: 'Número de compras',
                        value: dailySales?.numeroDeCompras ?? 0
                    });

                    return data;
                }
            )
        );

        this.monthlyNumberCards$ = this.metricsService.monthlySales$.pipe(
            map(
                dailySales => {
                    const data = [];
                    data.push({
                        name: 'Ticket médio',
                        value: dailySales?.ticketMedio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 'R$ 0,00'
                    });
                    data.push({
                        name: 'Unidades vendidas',
                        value: dailySales?.unidadesVendidas ?? 0
                    });
                    data.push({
                        name: 'Número de compras',
                        value: dailySales?.numeroDeCompras ?? 0
                    });

                    return data;
                }
            )
        );

        this.yearlyNumberCards$ = this.metricsService.yearlySales$.pipe(
            map(
                dailySales => {
                    const data = [];
                    data.push({
                        name: 'Ticket médio',
                        value: dailySales?.ticketMedio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? 'R$ 0,00'
                    });
                    data.push({
                        name: 'Unidades vendidas',
                        value: dailySales?.unidadesVendidas ?? 0
                    });
                    data.push({
                        name: 'Número de compras',
                        value: dailySales?.numeroDeCompras ?? 0
                    });

                    return data;
                }
            )
        );

        this.dailyProductsSales$ = this.metricsService.dailySales$.pipe(
            map(
                sales => this.createAndSortProductBarChart(sales?.produtosVendidos)
            )
        );


        this.monthlyProductsSales$ = this.metricsService.monthlySales$.pipe(
            map(
                sales => this.createAndSortProductBarChart(sales?.produtosVendidos)
            )
        );

        this.weeklyProductsSales$ = this.metricsService.weeklySales$.pipe(
            map(
                sales => this.createAndSortProductBarChart(sales?.produtosVendidos)
            )
        );

        this.yearlyProductsSales$ = this.metricsService.yearlySales$.pipe(
            map(
                sales => this.createAndSortProductBarChart(sales?.produtosVendidos)
            )
        );


    }


    private createAndSortProductBarChart(products) {

        if (!products) {
            return [];
        }

        const produtos = products;

        // group coffees by id

        const result = [];

        produtos.forEach(produto => {
            const index = result.findIndex(data => produto.coffee.id === data.id);

            if (index === -1) {

                result.push({
                    name: produto.coffee.name,
                    id: produto.coffee.id,
                    total: produto.quantity,
                    series: [
                        {
                            name: produto.optionId,
                            value: produto.quantity
                        }
                    ],
                });

            } else {
                const data = result[index];
                const series = data.series;
                const total = data.total;

                series.push(
                    {
                        name: produto.optionId,
                        value: produto.quantity
                    }
                );

                data.total = total + produto.quantity;
                data.series = series;

                result[index] = data;
            }
        });


        result.sort(
            (a, b) => b.total - a.total
        );

        return result.slice(0, 10);
    }

}
