import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { SalesMetric } from 'src/app/models/sales-metric.model';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  dailySales$: Observable<SalesMetric>;
  weeklySales$: Observable<SalesMetric>;
  monthlySales$: Observable<SalesMetric>;
  yearlySales$: Observable<SalesMetric>;

  last7weeks$: Observable<SalesMetric[]>;

  constructor(
    private firestore: AngularFirestore
  ) {
    const now = new Date();

    // Copy date so don't modify original
    const weekNow = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    weekNow.setUTCDate(weekNow.getUTCDate() + 4 - (weekNow.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(weekNow.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((weekNow.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

    const salesCollection = this.firestore.collection('sales');

    this.dailySales$ = salesCollection.doc<SalesMetric>(`day_${now.getDate()}_${now.getMonth()}_${now.getFullYear()}`).valueChanges();
    this.weeklySales$ = salesCollection.doc<SalesMetric>(`week_${weekNo}_${now.getFullYear()}`).valueChanges();
    this.monthlySales$ = salesCollection.doc<SalesMetric>(`month_${now.getMonth()}_${now.getFullYear()}`).valueChanges();
    this.yearlySales$ = salesCollection.doc<SalesMetric>(`year_${now.getFullYear()}`).valueChanges();

    const last7WeeksObservables: Observable<SalesMetric>[] = [];
    for (let i = 6; i >= 0; i -= 1) {
      const delta = weekNo - i;
      if (delta > 0) {
        last7WeeksObservables.push(salesCollection.doc<SalesMetric>(`week_${delta}_${now.getFullYear()}`).valueChanges(),)
      } else {
        last7WeeksObservables.push(salesCollection.doc<SalesMetric>(`week_${52 - delta}_${now.getFullYear() - 1}`).valueChanges(),)
      }

    }

    this.last7weeks$ = combineLatest(last7WeeksObservables);
  }
}
