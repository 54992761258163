<mat-sidenav-container fxFlexFill>
  <mat-sidenav position="end" #sidenav>
    <mat-nav-list>
      <a style="color: #7a4727;" *ngIf="(user$ | async)" routerLink="/" mat-list-item>Dashboard</a>
      <a style="color: #7a4727;" *ngIf="(user$ | async)" routerLink="/coffees" mat-list-item>Cafés</a>

      <div class="drawer-logo">
        <img src="assets\logos\logo_cor@4x.svg">
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill>

    <mat-toolbar [ngClass.lt-md]="{'small-toolbar': true}">
      <div fxHide.lt-md [ngClass.lt-md]="{'no-logo': true}" class="img-div"><img routerLink="/"
          src="assets\logos\logo_cor@4x.svg" class="logo"></div>
      <div [ngClass.lt-md]="{'small-font-logo': true}" routerLink="/" class="festival-cafe-title">
        Festival do Café
      </div>

      <span class="spacer"></span>

      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon style="color: #7a4727;">menu</mat-icon>
      </button>

      <button mat-button style="color: #7a4727;" fxHide.lt-md *ngIf="(user$ | async)" routerLink="/">Dashboard</button>
      <button mat-button style="color: #7a4727;" fxHide.lt-md *ngIf="(user$ | async)" routerLink="/coffees">Cafés</button>
      <button mat-button style="color: #7a4727;" fxHide.lt-md *ngIf="(user$ | async)" (click)="logout()">Logout</button>

    </mat-toolbar>

    <div class="r-outlet">
      <router-outlet></router-outlet>
    </div>

    <div [ngClass.lt-md]="{'footer-mobile': true}" class="footer">
      <div [ngClass.lt-md]="{'footer-content-mobile': true}" class="footer-content">
        <div [ngClass.lt-md]="{'footer-section-mobile': true}" class="footer-section about">
          <div class="img-name">
            <div [ngClass.gt-sm]="{'logo-img-big': true}" class="logo-img">
              <img src="assets\logos\logo_preto_vazado@4x.svg">
            </div>
            <h1 [ngClass.gt-sm]="{'title-big': true}" class="title">Festival do Café</h1>
          </div>
          <h2 class="section-title">Siga-nos nas nossas redes oficiais</h2>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/festivaldocafe.br"
            class="fa fa-facebook"></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/festivaldocafe/"
            class="fa fa-instagram"></a>
        </div>

      </div>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>