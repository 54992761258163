<div fxLayout="column" fxLayoutAlign="center center">

    <div class="logo">
        <img src="assets/logos/logo_cor@4x.svg">
    </div>

    <form fxLayout="column" (ngSubmit)="onSubmit(form)" #form="ngForm">
        
        <mat-form-field>
            <input matInput type="email" ngModel name="email" placeholder="Email" required>
        </mat-form-field>

        <mat-form-field>
            <input matInput type="password" ngModel name="pass" placeholder="Senha" required>
        </mat-form-field>

        <button mat-button type="submit">Login</button>

    </form>

</div>
