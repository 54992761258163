import { DeleteLojaDialogComponent } from './../delete-loja-dialog/delete-loja-dialog.component';
import { LojaService } from './../../services/loja/loja.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Loja } from 'src/app/models/loja.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateLojaDialogComponent } from '../create-loja-dialog/create-loja-dialog.component';

@Component({
  selector: 'app-listar-lojas-dialog',
  templateUrl: './listar-lojas-dialog.component.html',
  styleUrls: ['./listar-lojas-dialog.component.css']
})
export class ListarLojasDialogComponent implements OnInit {
  lojas$: Observable<Loja[]>;

  displayedColumns: string[] = ['name', 'id', 'select', 'editar', 'excluir'];

  constructor(
    private lojaService: LojaService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ListarLojasDialogComponent>,
  ) {
    this.lojas$ = this.lojaService.lojas$;

  }

  ngOnInit(): void {

  }

  createLoja() {
    const dialogRef = this.dialog.open(CreateLojaDialogComponent, {
      width: '500px',
    });
  }

  editLoja(loja) {
    const dialogRef = this.dialog.open(CreateLojaDialogComponent, {
      width: '500px',
      data: loja
    });
  }

  deleteLoja(loja) {
    const dialogRef = this.dialog.open(DeleteLojaDialogComponent, {
      width: '500px',
      data: loja
    });
  }

  selectLoja(loja) {
    this.dialogRef.close(loja);
  }
}
