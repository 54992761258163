import { DbService } from './../../services/db.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(
    private dbService: DbService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm): void {
    const codigo = form.value.codigo;

    this.dbService.checkCode(codigo).then(
      data => {
        const found = data.found;

        if (found) {
          this.router.navigateByUrl('/dados')
        } else {
          // console.log('nao encontrado');
        }
      }
    );
  }

}
