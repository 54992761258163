import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Coffee } from 'src/app/models/coffee.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CoffeeService {
    coffees$: Observable<Coffee[]>;

    constructor(
        private firestore: AngularFirestore
    ) {
        this.coffees$ = firestore.collection<Coffee>('coffees').valueChanges({idField: 'id'});
    }

    async createCoffee(coffee: Coffee): Promise<void> {

        if (!coffee.id) {
            coffee.id = this.firestore.createId();
        }

        coffee.options.forEach(
            option => {
                if (!option.id) {
                    option.id = this.firestore.createId();
                }
            }
        );

        return this.firestore.collection('coffees').doc(coffee.id).set(coffee);
    }

    async updateCoffee(coffee: Coffee): Promise<void> {
        return this.firestore.collection('coffees').doc(coffee.id).update(coffee);
    }

    async deleteCoffee(coffee: Coffee): Promise<void> {
        return this.firestore.collection('coffees').doc(coffee.id).delete();
    }

    getCoffee(id) {
        return this.firestore.collection('coffees').doc(id).get().pipe(
            map(coffeeDoc => {
                return {
                    id: coffeeDoc.id,
                    ...coffeeDoc.data()
                } as Coffee;
            })
        );
    }
}
